/* .organization {
    padding: 20px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
} */

.organization-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.organization-description {
    font-size: 16px;
    margin-bottom: 10px;
}